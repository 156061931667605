import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

import BlockSection from "../components/blockSection"
import BlockContentGrid from "../components/blockContentGrid"

const GenericPage = () => {
  const pageTitle = "Freshly Commerce"

  return (
    <Layout pageTitle={pageTitle}>
      <Seo title={pageTitle} />

      <Hero
        heroText="Privacy Policy"
        heroDesc={
          <>
            Please download our privacy policy{" "}
            <a href="/privacy.pdf" download>
              here
            </a>
            . Last updated March 15th, 2024.
          </>
        }
      />
    </Layout>
  )
}
export default GenericPage
